import CDialog from "@corvu/dialog";
import { createSignal, For, Show } from "solid-js";
import { Icon } from "solid-heroicons";
import { xMark } from "solid-heroicons/outline";

export const usePrompt = () => {
  const [config, setConfig] = createSignal<
    | {
        title: string;
        description?: string;
        options: PromptButtonOption<any>[];
        requireInteraction?: boolean;
      }
    | undefined
  >(undefined);
  let resolve: (value: any) => void;
  let promise: Promise<any>;

  const closePrompt = () => setConfig(undefined);

  const closeUnlessRequired = () => {
    if (config()?.requireInteraction) return;
    closePrompt();
  };

  const buttonStyle = {
    default: "border-black text-black",
    red: "border-red-600 bg-red-600 text-white",
    green: "border-green-500 bg-green-500 text-white",
    blue: "border-roma-blue bg-roma-blue text-white",
  } as const;

  const prompt = (
    <Show when={config()}>
      <CDialog
        role="alertdialog"
        open={true}
        onFinalFocus={closeUnlessRequired}
        onOutsidePointer={closeUnlessRequired}
        onEscapeKeyDown={closeUnlessRequired}
        onOutsideFocus={closeUnlessRequired}
      >
        <CDialog.Portal>
          <CDialog.Overlay class="fixed inset-0 z-50 bg-black/50" />
          <CDialog.Content class="fixed left-1/2 top-1/2 z-50 min-w-[320px] -translate-x-1/2 -translate-y-1/2 rounded-lg  bg-white px-6 py-5 duration-200 corvu-open:animate-in corvu-open:fade-in-0 corvu-open:zoom-in-95 corvu-open:slide-in-from-left-1/2 corvu-open:slide-in-from-top-[60%] corvu-closed:animate-out corvu-closed:fade-out-0 corvu-closed:zoom-out-95 corvu-closed:slide-out-to-left-1/2 corvu-closed:slide-out-to-top-[60%] ">
            <div class="flex justify-between items-start space-x-2">
              <CDialog.Label class="text-lg font-bold">
                {config()?.title}
              </CDialog.Label>
              <CDialog.Close
                class="ml-auto p-1"
                as="button"
                onClick={closePrompt}
              >
                <Icon path={xMark} class="w-5 h-5" />
                <span class="sr-only">Close alert window</span>
              </CDialog.Close>
            </div>

            <Show when={config()?.description}>
              <div class="mt-4">{config()?.description}</div>
            </Show>

            <div class="mt-8 flex items-center space-x-2">
              <For each={config()?.options}>
                {(option) => (
                  <button
                    class={`rounded-lg border-2 py-2 px-4 ${
                      option.priority ? "grow" : "grow-0"
                    } ${
                      option.style
                        ? buttonStyle[option.style]
                        : buttonStyle["default"]
                    } active:translate-y-0.5`}
                    onClick={() => {
                      resolve(option.value);
                      closePrompt();
                    }}
                  >
                    {option.label}
                  </button>
                )}
              </For>
            </div>
          </CDialog.Content>
        </CDialog.Portal>
      </CDialog>
    </Show>
  );

  const open = <T,>(config: {
    title: string;
    description?: string;
    options: PromptButtonOption<T>[];
    requireInteraction?: boolean;
  }) => {
    promise = new Promise<T>((_resolve) => (resolve = _resolve));
    setConfig(config);
    return promise as Promise<T>;
  };

  return [prompt, open] as const;
};

type PromptButtonOption<T> = {
  value: T;
  label: string;
  priority?: boolean;
  style?: "red" | "green" | "blue" | "default";
};
