import { FlowComponent, Show } from "solid-js";
import { Icon } from "solid-heroicons";
import {
  informationCircle,
  exclamationCircle,
  xMark,
} from "solid-heroicons/solid";

export const InfoBox: FlowComponent<{
  bgColour?: "warning" | "error";
  icon?: IconPath;
  type?: "warning" | "error";
  close?: VoidFunction;
  class?: string;
}> = (props) => {
  return (
    <div
      class="border bg-faint-blue px-4 py-5 flex gap-1 items-center relative"
      classList={{
        "bg-red-50": props.type === "error",
        "bg-orange-50": props.type === "warning",
        "bg-faint-blue": !props.type,
        [`${props.class}`]: !!props.class,
      }}
    >
      <Show
        when={props.icon}
        fallback={
          <Icon
            path={
              props.type == "warning" || props.type == "error"
                ? exclamationCircle
                : informationCircle
            }
            class="w-6 mb-1 shrink-0"
          />
        }
      >
        <Icon path={props.icon as IconPath} class="w-5 mb-1 shrink-0" />
      </Show>
      <div class="ml-2 text-sm">{props.children}</div>
      <Show when={props.close}>
        <button
          aria-label="Close"
          class="p-2 absolute top-0 right-1"
          onClick={() => {
            props.close && props.close();
          }}
        >
          <Icon path={xMark} class="w-5" />
        </button>
      </Show>
    </div>
  );
};
